import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FileDownload from 'js-file-download';

const RecommendToolkit = () => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();

  let navigate = useNavigate();

  const [recommends, setRecommend] = useState([]);
  const [toolkit, setToolkit] = useState([]);
  const params = useParams();

  var viewUrl = `${process.env.REACT_APP_BASEURL_API}/view/${params.id}`;

  var subUrl = `${process.env.REACT_APP_BASEURL_API}/subscribe`;

  //  var downUrl = `${process.env.REACT_APP_BASEURL_API}/download`;

  var recUrl = `${process.env.REACT_APP_BASEURL_API}/viewlimit`;

  var imgUrl = `${process.env.REACT_APP_BASEURL_API}/toolkits`;

  const getToolkit = async () => {

    try {

      const response = await fetch(viewUrl, {
        method: "GET"
      },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          // mode: 'no-cors',
        });
      const jsonData = await response.json();
      setToolkit(jsonData);
    } catch (err) {
      console.error("Cannot find Data");
    }
  }
  useEffect(() => {
    getToolkit();
  }, []);


  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  const subscribe = async (e) => {
    e.preventDefault(); // to prevent refreshing after submit
    if (validateEmail(email) === true) {
      if (name === undefined) {
        console.log("Name is undefined");
      } else if (name.length === 0) {
        console.log("Name is Empty");
      } else {


        try {
          const emailer = { email, name };
          const response = await fetch(subUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(emailer)
          });
          console.log(emailer);
        } catch (err) {
          console.error(err.message);
        }

        console.log("Good to Download");
      }

    } else {
      console.log("Please input a valid email");
      alert("Please input a valid email");
    }
  }

  //   const fileDownload=(e)=>{
  //    e.preventDefault()
  //     if (validateEmail(email) === true) {
  //    axios({
  //        url: `${downUrl}/${params.id}`,
  //        method: "GET",
  //        responseType: "blob"
  //    },{
  //     headers: { "Content-Type": "application/json",
  //     "Accept": "application/json",

  // },
  //     // Always write this in order not to have CORS issues
  //     withCredentials: false,
  //     crossdomain: false,
  //     // mode: 'no-cors',
  //    }).then((res)=>{
  //       FileDownload(res.data, toolkit.toolkit_file)
  //       window.location = "/";
  //    })
  //    } else{
  //       console.log("Please input a valid email"); 
  //    }  
  // }



  const getRecommend = async () => {
    const pageURL = recUrl;
    try {
      const response = await fetch(pageURL);
      const jsonData = await response.json();

      setRecommend(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    getRecommend();
  }, []);


  return (

    <Fragment>
      <div className="container row">
        <div className="col-sm-1">
          <Link to={'/'} className="text-link">
            <h4 className="card-title keyar"> BACK </h4>
          </Link>
        </div>

        <div className="col-sm-10 ">
          <div className="row col-sm-12 cardiz">
            <div className="col-sm-6 ">
              <div className="card cardiza">
                <img className="preview-img" src={`${imgUrl}/${toolkit.toolkit_img}`} alt="Toolkit_Image" />
              </div>
              <p className="vkit"></p>
            </div>
            <div className="col-sm-4 offset-1 create">
              <h4 className="card-title text-capitalize"> {toolkit.title} </h4>
              <h4 className="card-title"> cost: ${toolkit.price}</h4>


              <form className="form forma">
                <input type="text" name="" onChange={(e) => setName(e.target.value)} placeholder="Full Name" required />
                <input type="email" name="" onChange={(e) => setEmail(e.target.value)} placeholder="example@email.com" required />
                {!email && <button className="disabledbtn" disabled>DOWNLOAD</button>}
                {/* {email && <button className="downloadbtn btn-success" onClick={(e) => { subscribe(e); fileDownload(e); }}>DOWNLOAD</button>} */}
                {email && <button className="downloadbtn btn-success" target="_blanc" onClick={(e) => {
                  subscribe(e);

                  e.preventDefault()
                  if (validateEmail(email) === true) {
                    if (name === undefined) {
                      e.preventDefault()
                      alert("Please input Your Full Name ")
                    } else if (name.length === 0) {
                      e.preventDefault()
                      alert("Name cannot be Empty ")
                    } else {
                      window.location.href = `${toolkit.toolkit_file}`;
                      navigate('/thankyou');
                    }

                  }

                }}>DOWNLOAD</button>}

              </form>


            </div>
          </div>

          <div className="row col-sm-12 cardio">
            <div className="col-sm-6">
              <div className="accordion accordion-flush" id="accordionFlushExample">

                <div className="btn-group">
                  <button className="btn btn-sm btn-default accordion-btn" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    DESCRIPTION
                    {/* <hr className="act"/> */}
                  </button>

                  <button className="btn btn-sm btn-default accordion-btn" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    IMPORTANT INFO
                    {/* <hr className="act"/> */}
                  </button>
                </div>

                <div className="accordion-item">
                  <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"> {toolkit.description} </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"> {toolkit.important_info} </div>
                  </div>
                </div>
              </div>


            </div>



          </div>


        </div>

      </div>
      <div className="py-5 reco">
        <h3 className="text-center">RECOMMENDED TOOLKITS</h3>
      </div>



      <div className="col-sm-12 row recom">
        {recommends.map(recommend => (
          <div className="col-sm-3 cardozor" key={recommend.id}>
            <div className="card">
              <Link to={`/view/${recommend.id}`}>
                <img className="preview-img" src={`${imgUrl}/${recommend.toolkit_img}`} alt="Toolkit_Image" />
              </Link>
            </div>
            <Link to={`/view/${recommend.id}`} className="text-link">
              <h4 className="card-title">{recommend.title}</h4>
            </Link>
          </div>
        ))}


      </div>


    </Fragment>
  );
}



export default RecommendToolkit;


