import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ThankYou from './pages/ThankYou';
import ViewToolkit from './pages/ViewToolkit';
import RecommendToolkit from './pages/Recommend';
import PageError from './components/PageError';
// import Household from './pages/Household';
// import Childcare from './pages/Childcare';
// import Work from './pages/Work';


function App() {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   return () => {
  //     setTimeout(()=> {
  //       setLoading(false)
  //     },1000) // set time for loader to 2 secs
  //   };
  // }, [])

  return (


    <Router>
      <div className="App">

        {/* {!loading &&  <Navbar/>  } */}
        <div className="content">

          {/* {loading ? (<PageLoader />) :  */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/thankyou" element={<ThankYou />} />
            {/* <Route path="/free" element={<FreeToolkit />} /> */}
            {/* <Route path="/paid" element={<PaidToolkit />} /> */}
            {/* <Route path="/childcare" element={<Childcare />} /> */}
            {/* <Route path="/household" element={<Household />} /> */}
            {/* <Route path="/work" element={<Work />} /> */}
            <Route path="/view/:id" element={<ViewToolkit />} />
            <Route path="/recommend/:id" element={<RecommendToolkit />} />

            <Route path="*" element={<PageError />} />
          </Routes>
          {/* }     */}
        </div>
        {/* {!loading &&  <Footer /> } */}

      </div>
    </Router>

  );
}

export default App;
