import { Link } from "react-router-dom";

const ThankYou = () => {
    return (
        <div className="text-center">
            <img className="img-responsive thanks-img" src="./thanks.png" alt="Thanks" />

            <div className="thanks mt-3" style={{ fontFamily: 'Montserrat' }}>
                Thanks for downloading our "Toolkit" designed to help you organize your home, and <br />
                make important decisions on your workplace and career. <br />
                <button onClick={(e) => { window.location.href = "https://motherhonestly.com/membership/" }} style={{ color: 'royalblue', textDecoration: 'none', fontFamily: 'Montserrat', border: 'none', backgroundColor: 'transparent' }}> Start your 30-day MH Membership free trial here </button>

            </div>

            <Link to={"/"} className="btn mt-5" style={{ backgroundColor: '#f2ea59', fontFamily: 'Montserrat' }}> BACK TO TOOLKIT </Link>
        </div>
    );
}

export default ThankYou;