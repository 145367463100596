import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Fade from 'react-reveal/Fade';

const Home = () => {
  const [toolkits, setToolkits] = useState([]);
  const [toolz, setToolz] = useState([]);

  var Url = `${process.env.REACT_APP_BASEURL_API}/view`;

  var imageUrl = `${process.env.REACT_APP_BASEURL_API}`;

  const getToolkits = async () => {
    const pageURL = Url;


    try {
      const response = await fetch(pageURL,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          // Always write this in order not to have CORS issues
          withCredentials: false,
          crossdomain: false,
          // mode: 'no-cors',
        });
      const jsonData = await response.json();

      setToolkits(jsonData);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    getToolkits();
  }, [])

  const searchToolz = (event) => {
    setToolz(event.target.value);
  }


  // toolkits is the array of data collected
  // dataSearch is helping to filter the toolkits

  let dataSearch = toolkits.filter(item => {
    return Object.keys(item).some(key => item[key].toString().toLowerCase().includes(toolz.toString()
      .toLowerCase()
    ))
  });


  return (
    <Fragment>



      <div className="container mt-5">

        <div className="row toolz">
          {/* <div className="col-sm-2 offset-1 order-first tkit"> <p>Toolkits </p> </div> */}

          {/* <div className="offset-7 col-sm-2 search">
             <input
                type="text"
                required
                name="title"
                placeholder="Search toolkits"
                value={toolz}
                onChange={searchToolz.bind(this)}
                 />
             </div> */}
        </div>
      </div>

      <div className="container mt-5">
        <div className="row cat">
          {/* <div className="col-sm-2 offset-1 order-first cat"> 

 
            <div className="dropdown flik">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Sort Toolkits
              </button>
              <ul className="dropdown-menu flik-menu" aria-labelledby="dropdownMenuButton1">
                <li><Link className="dropdown-item flik-item" to={"/childcare"}>Child Care</Link></li>
                <li><Link className="dropdown-item flik-item" to={"/household"}>Household</Link></li>
                <li><Link className="dropdown-item flik-item" to={"/work"}>Work</Link></li>
              </ul>
            </div>
<br />
            
            </div> */}


          <div className="col-sm-12 row shift-phone shift">

            {dataSearch.map(toolkit => (

              <div className="col-sm-3 cardozo" key={toolkit.id}>
                {/* <Fade bottom duration={800} delay={500}> */}
                <div className="card">
                  <Link to={`/view/${toolkit.id}`}>
                    <img className="preview-img" src={`${imageUrl}/toolkits/${toolkit.toolkit_img}`} alt="Toolkit_Image" />
                  </Link>

                </div>
                {/* <Fade bottom duration={1200} delay={500}> */}
                <Link to={`/view/${toolkit.id}`} className="text-link">
                  <h4 className="card-title">{toolkit.title} </h4>
                </Link>
                {/* </Fade>   */}
                {/* </Fade>    */}
              </div>
            ))}





          </div>

          <div className="foot"></div>

        </div>
      </div>









    </Fragment>

  );
}

export default Home;